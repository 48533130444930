import { ref, computed, Ref } from 'vue'
import { router } from '@inertiajs/vue3'
import { RequestPayload } from '@inertiajs/core'
import dayjs from 'dayjs'
import { RoomType } from '@/Pages/the-home/enums/Search'
import { ResultOptions, ResultOptionsOrderBy, SearchOptions } from '@/Interfaces/SearchOptions'
import { filterOptions } from './use-listing-filter'
import { currencyForServer } from './currencyExchange'

export const orderByDefault: ResultOptionsOrderBy = {
    sortBy: 'ratings',
    type: 'ratings',
    value: 'Rating (Default)',
    title: 'Rating (Default)'
}

const resultOptions: Ref<ResultOptions> = ref({
    mapMode: 'closed',
    listFormat: 'list',
    orderBy: orderByDefault
})

const currentPage = ref(1)

export const searchOptions: Ref<SearchOptions> = ref({
    startDate: dayjs().format('YYYY-MM-DD'),
    endDate: dayjs().add(2, 'day').format('YYYY-MM-DD'),
    nights: 1,
    roomType: RoomType.DORM,
    people: 1,
    rooms: 1,
    groupType: '',
    groupAgeRanges: [],
    currency: 'USD',
    skipDate: false
})

const currency = computed(() => currencyForServer.value || searchOptions.value.currency)

export const updatedSearchOptions = computed(() => ({
    ...searchOptions.value,
    currency: currency.value
}))

const searchData = computed((): unknown => ({
    searchOptions: updatedSearchOptions.value,
    page: currentPage.value,
    resultsOptions: resultOptions.value,
    activeFiltersOptions: filterOptions.value
}))

export const updateSearchOptions = (data: SearchOptions): void => {
    searchOptions.value = data
}

export const updateCurrentPage = (page: number): void => {
    currentPage.value = page
}

export const updateSortBy = (data: ResultOptionsOrderBy): void => {
    resultOptions.value.orderBy = data
}

export const updateRoomType = (roomType: RoomType): void => {
    searchOptions.value.roomType = roomType
}

export const updateRoomsQuantity = (rooms: number): void => {
    searchOptions.value.rooms = rooms
}

export const updatePeopleQuantity = (people: number): void => {
    searchOptions.value.people = people
}

export const updateGroupType = (groupType: string): void => {
    searchOptions.value.groupType = groupType
}

export const updateGroupAgeRanges = (groupAgeRanges: []): void => {
    searchOptions.value.groupAgeRanges = groupAgeRanges
}

export const updateSkipDate = (skipDate: boolean): void => {
    searchOptions.value.skipDate = skipDate
}

export const updateDateRange = (range: string[]): void => {
    searchOptions.value.startDate = dayjs(range[0]).format('YYYY-MM-DD')
    searchOptions.value.endDate = dayjs(range[1]).format('YYYY-MM-DD')
    searchOptions.value.nights = dayjs(searchOptions.value.endDate).diff(searchOptions.value.startDate, 'day')
}

export const isLoading = ref(false)

export const partialReload = (): void => {
    isLoading.value = true

    router.reload({
        only: ['listingsData'],
        data: searchData.value as RequestPayload,
        method: 'post',
        onSuccess: () => {
            isLoading.value = false

            console.log('partial')
        }
    })
}

export const fullReload = (url: string): void => {
    isLoading.value = true

    router.post(
        url,
        searchData.value as RequestPayload,
        {
            onSuccess: (page) => {
                if (!page.props.listingsData) {
                    console.log('full with partial')

                    partialReload()
                } else {
                    isLoading.value = false

                    console.log('full')
                }
            }
        }
    )
}